@import url("https://fonts.googleapis.com/css2?family=Poly:ital@0;1&display=swap");

@font-face {
  font-family: "Saint George";
  font-weight: 400;
  src: url("fonts/saint-george.woff2");
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poly", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  line-height: 1.1;
  font-weight: 300;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Saint George", sans-serif; */
  margin-top: 0;
  font-weight: 400;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6 {
  margin-bottom: 0;
}

video {
  display: none;
}

a {
  color: white;
}

img {
  width: 100%;
  height: auto;
}

.row {
  display: flex;
  justify-content: space-around;
}

.detail {
  text-transform: uppercase;
}

.green {
  color: #02ff02;
  text-transform: uppercase;
  font-weight: bold;
}

.red {
  color: #ff0000;
  text-transform: uppercase;
  font-weight: bold;
}

input[type="text"],
textarea {
  background: none;
  border: 1px solid white;
  color: white;
  border-radius: 3px;
}

textarea {
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}

button {
  border: 1px solid white;
  background: none;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  min-width: 5em;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.credits {
  opacity: 0.7;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 12px;
}

.credits:hover {
  opacity: 1;
}
